import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/resources-banner-bg.jpg"
import ResourcesTab from "../components/resources/resources-tab"

const LinksPage = () => {

    return <Layout colored_header={true}>
        <Seo title="Links" />
        <InnerBanner theme="white" image={banner} title="Resources" />

         <ResourcesTab.Tab2 />


        <HowToHelp />
    </Layout>
}

export default LinksPage